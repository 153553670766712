<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 4 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 4: Homeowner Info
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_1"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #1 Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_2"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #2 Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #3 Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_4"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="this.selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="this.selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Projected Closing Date"
                                    color="black"
                                    type="date"
                                    v-model="this.selected_user.BuildEnd"
                                />
                            </v-col>

                          <v-col col="12" md="12">
                                <v-header><strong>Additional Information/Notes</strong><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_5"/></v-header>
                            </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 4: Miscellaneous
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                         <v-row>
                           <v-col cols="12" md="12"><v-header><strong>OTHER ITEMS TO CONSIDER:</strong></v-header></v-col>
                           <v-col cols="12" md="12"><v-header><strong>FIREPLACE:</strong> STANDARD HEAT-N-GLO 6000C UNIT WITH STD BLACK FOLIO—36 FRONT</v-header></v-col>
                           <v-col cols="12" md="2"><v-subheader>NATURAL GAS:</v-subheader></v-col>
                            <v-col cols="12" md="4"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_6"/></v-col>
                           <v-col cols="12" md="2"><v-subheader>Fan: Optional ${{this.price_section_four.column_14}}:</v-subheader></v-col>
                            <v-col cols="12" md="4"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_7"/></v-col>
                            <v-col cols="12" md="1"><v-subheader>LP GAS:</v-subheader></v-col>
                            <v-col cols="12" md="4"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_8"/></v-col>
                           <v-col cols="12" md="2"><v-subheader>REMOTE: OPTIONAL ${{this.price_section_four.column_15}}:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_9"/></v-col>
                            <v-col cols="12" ><v-subheader>STD. ON/OFF WALL SWITCH: YES - TO BE DETERMINED AT ELECTRICAL WALK THROUGH</v-subheader></v-col>
                           <v-col cols="12" md="4" ><v-subheader>Bump out”  the base (into room)  0” (flush w/wall):</v-subheader></v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'"  :items="['6','12','Undecided']" v-model="section.column_10"/></v-col>
                           <v-col cols="12" md="1" ><v-subheader>Hearth:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Flush w/floor','Raised  12','Undecided']" v-model="section.column_11"/></v-col>
                           <v-col cols="12" md="4" ><v-subheader>Mantel height (measured from floor to top of mantel):</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['STD. Height','Custom Height','Undecided']" v-model="section.column_12"/></v-col>
                           <v-col cols="12" md="2" ><v-subheader>Custom Height:</v-subheader></v-col>
                           <v-col cols="12" md="3" ><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_13"></v-text-field></v-col>
                            <v-col cols="12" ><v-text-field :readonly="permissions === 'read:homeowner'" label="ADDITIONAL FIREPLACE LOCATION" v-model="section.column_14"></v-text-field></v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12"><v-header><strong>CENTRAL AIR CONDITIONING:</strong> YES <strong>X</strong> TO MEET HOME DESIGN</v-header></v-col>
                          <v-col cols="12" md="2" ><v-subheader>Electrical Amp:</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['150 AMP ELECTRICAL STD. RAMBLER HOUSE PLANS',`200 AMP ELECTRICAL $${this.price_section_four.column_16} (IF NOT ALREADY PROVIDED)`,'Undecided']" v-model="section.column_15"/></v-col>
                            <v-col cols="12" ><v-subheader>NOTE: If appliance hookups are electric and central air is installed, you may want to upgrade to 200 AMP. Conversion to 200 AMP later in the building process will result in higher installation costs to homeowner.</v-subheader></v-col>
                          <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_16"/></v-header>
                            </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="4"><v-subheader>GARAGE SERVICE DOOR (STANDARD EC300 STEEL 3 PANEL) ${{this.price_section_four.column_17}}:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_17"></v-select></v-col>
                            <v-col cols="12" md="5" ><v-subheader>IF YES, ADDITIONAL LIGHTING ALLOWANCE MAY BE REQUIRED OF HOMEOWNER</v-subheader></v-col>
                           <v-col cols="12" md="5" ><v-subheader>GARAGE DOOR OPENERS: *1/2 HSP CHAIN DRIVE W/ (2) TRANSMITTERS ${{this.price_section_four.column_18}}:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_18"></v-select></v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" label="LARGE DOOR" :items="['Yes','No','Undecided']"  v-model="section.column_19"/></v-col>
                          <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" label="SMALL DOOR" :items="['Yes','No','Undecided']"  v-model="section.column_20"/></v-col>
                          <v-col cols="12" md="5" ><v-subheader>8 FT HEIGHT DOORS:  *1/2 HSP CHAIN DRIVE  ${{this.price_section_four.column_19}}:</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_30"></v-select></v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" label="LARGE DOOR" :items="['Yes','No','Undecided']"  v-model="section.column_21"/></v-col>
                          <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" label="SMALL DOOR" :items="['Yes','No','Undecided']"  v-model="section.column_22"/></v-col>
                          <v-col cols="12" md="3" ><v-subheader>KEYLESS ENTRY  ${{this.price_section_four.column_20}}:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_23"></v-select></v-col>
                          <v-col cols="12" md="6"><v-select :readonly="permissions === 'read:homeowner'" label="NUMBER OF:" :items="['1','2', '3', '4', '5', '6','Undecided']"  v-model="section.column_24"/></v-col>
                          <v-col cols="12" md="3" ><v-subheader>My Q” for SMART PHONES ${{this.price_section_four.column_21}}:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_25"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>GARAGE DOORS STD. SIZE 7FT. HEIGHT:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_26"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>${{this.price_section_four.column_22}} OPTIONAL 8 FT. HEIGHT BOTH DOORS: </v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_27"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>GLASS OPTIONAL TOP ROW BOTH DOORS ${{this.price_section_four.column_23}}:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_28"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>HOMEOWNER TO CHANGE DESIGN:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_29"></v-select></v-col>
                          <v-col cols="12" md="6" ><v-subheader>(WEBSITE for AKER DOORS:  <a href='www.akerdoors.com' target='_blank'>www.akerdoors.com</a>)</v-subheader></v-col>
                          <v-col cols="12" md="8" ><v-subheader>UPGRADED DECORATIVE BLACK OUTSIDE HARDWARE.(HINGES & HANDLE BOTH DOORS) ${{this.price_section_four.column_24}}:</v-subheader></v-col>
                            <v-col cols="12" md="4"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_30"></v-select></v-col>
                          <v-col cols="12" md="12" ><v-header><strong>*INSULATED GARAGE DOORS: </strong></v-header></v-col>
                          <v-col cols="12" md="3" ><v-subheader>16’ DOOR ${{this.price_section_four.column_25}} (Standard insulated material):</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_31"/></v-col>
                          <v-col cols="12" md="3" ><v-header>8’ DOOR ${{this.price_section_four.column_26}} (Standard insulated material):</v-header></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_32"/></v-col>
                          <v-col cols="12" md="12" ><v-header><strong>*THERMAL STEEL INTERIOR SKIN:</strong></v-header></v-col>
                          <v-col cols="12" md="3" ><v-subheader>16’ DOOR ${{this.price_section_four.column_27}} (Standard insulated material):</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_33"/></v-col>
                          <v-col cols="12" md="3" ><v-header>8’ DOOR ${{this.price_section_four.column_28}} (Standard insulated material):</v-header></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_34"/></v-col>
                          <v-col cols="12" md="3" ><v-subheader>CUSTOM GARAGE DOOR:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_35"></v-select></v-col>
                          <v-col cols="12" md="6"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_36" ></v-text-field></v-col>
                            </v-row>
                        <v-row>
                          <v-col cols="12" md="3" ><v-subheader> OPTIONAL, INSULATE ENTIRE GARAGE (Bid Only):</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_37"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader> HOMEOWNER TO INSULATE GARAGE AFTER ELECTRICAL WALK THROUGH:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']"  v-model="section.column_38"></v-select></v-col>
                           <v-col cols="12" md="12" ><v-subheader> *SHEET ROCK ENTIRE GARAGE: STANDARD:</v-subheader></v-col>
                            <v-col cols="12"  md="12"><v-subheader>(EACH HOME WILL VARY ON AMOUNTS OF STANDARD AREAS OF SHEET ROCK IN GARAGE DUE TO CITY FIRE CODE VARIANCES)</v-subheader></v-col>
                          <v-col cols="12"  md="12"><v-subheader>CEILING (INTERIOR OF HOME) STANDARD KNOCK DOWN ALL FINISHED FLOORS: YES <strong>XX</strong></v-subheader></v-col>
                          <v-col cols="12" md="3" ><v-subheader> WATER SOFTENER LOOP INSTALL (${{this.price_section_four.column_29}}):</v-subheader></v-col>
                            <v-col cols="3"><v-select :readonly="permissions === 'read:homeowner'"  :items="['Yes','No','Undecided']" v-model="section.column_39"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>WATER SOFTENER LOOP AND HOOK UP OF HOMEOWNER’S UNIT (${{this.price_section_four.column_40}})::</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_40"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>WATER SOFTENER UNIT (42,000 GRAIN NSC42) ${{this.price_section_four.column_30}}, LOOP INCLUDED:</v-subheader></v-col>
                            <v-col cols="12" md="3 "><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_41"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader> HOMEOWNER TO CONTACT THEIR OWN WATER SOFTENER COMPANY:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_42"></v-select></v-col>
                          <v-col cols="12" md="12" ><v-subheader> ALL HOT WATER HEATERS ARE 50 GALLON ELECTRIC UNITS:</v-subheader></v-col>
                            <v-col cols="12" md="12" ><v-subheader>50 GALLON GAS HOT WATER HEATERS WITH POWER VENTILATION SYSTEMS ARE AVAILABLE WITH ADDITIONAL COST QUOTES UPON REQUEST BEFORE PLUMBING ROUGH-INS ARE COMPLETED. PRICING ${{this.price_section_four.column_31}}. UPGRADE.</v-subheader></v-col>
                          <v-col cols="12" md="3" ><v-subheader> SPECIALTY INTERIOR WIRING:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"  v-model="section.column_43"></v-select></v-col>
                           <v-col cols="12" md="6" ><v-subheader>(Includes Smart Home Package installation)</v-subheader></v-col>
                            <v-col cols="12" ><v-subheader>(WEBCO HOME SECURITY #763-767-7229 contact: Randy Stefanick) IT WILL BE HOMEOWNER’S RESPONSIBILITY TO MEET CONTRACTOR AT HOUSE TO DISCUSS OPTIONS AND PRICING.</v-subheader></v-col>
                          <v-col cols="12" md="3" ><v-subheader> GUTTER AND DOWNSPOUTS - REQUEST BID:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']"   v-model="section.column_44"></v-select></v-col>
                            <v-col col="12" md="12">
                                <v-header>Locations:<v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_45"/></v-header>
                            </v-col>
                        </v-row>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </v-col>
                      </template> -->
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select :readonly="permissions === 'read:homeowner'"
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                           </v-dialog>
                    </v-container>
                </v-form>
                </base-material-card>
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                     v-model="section.column_46"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_47"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_48"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_49"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
    name:'Section4',
   data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      section_id: "4",
      dialog: null,
      valid: null,
      staff_email: 'steve@skelleymarketing.com',
      add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      price_section_four: {
        column_14: '',
        column_15: '',
        column_16: '',
        column_17: '',
        column_18: '',
        column_19: '',
        column_20: '',
        column_21: '',
        column_22: '',
        column_23: '',
        column_24: '',
        column_25: '',
        column_26: '',
        column_27: '',
        column_28: '',
        column_29: '',
        column_30: '',
        column_31: '',
        column_40: '',
      },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_30:'',
        column_31:'',
        column_32:'',
        column_33:'',
        column_34:'',
        column_35:'',
        column_36:'',
        column_37:'',
        column_38:'',
        column_39:'',
        column_40:'',
        column_41:'',
        column_42:'',
        column_43:'',
        column_44:'',
        column_45:'',
        column_46:'',
        column_47:'',
        column_48:'',
        column_49:''
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
    }
  },
  created () {
    this.getSection()
    this.getHomePrice()
    this.getVendorEmail()
    this.getUserInfo()
    },
    methods:{

        getSection() {
            axios
            .get(process.env.VUE_APP_GET_SECTION_TWO_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.section = JSON.parse(result['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getUserInfo() {
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
       getHomePrice(){
            axios
            .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.price_section_four = JSON.parse(result['SectionFour']) || {}
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getVendorEmail () {
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,
          {
            // params: {
            // }
          }
          )
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
      sectionComplete(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
                this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
            })
            .catch(error => {
            console.log(error)
            })
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
          this.close()
        },
      close () {
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
      sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
    }
}
</script>